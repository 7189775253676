import React, { useState, useEffect } from 'react'
import Layout from '../layouts/defaultLayout'
import { withTranslation } from 'react-i18next'
import birdAvatar from '../assets/images/bird-avatar.png'
import { Typography, Grid } from '@material-ui/core'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { styles } from '../pagesStyles/categoryListing'
import ProductsSort from '../components/productsSort'
import CHRSectionContainer from './../components/sectionContainer'
import CHRArrowButton from './../components/arrowButton'
import { graphql } from 'gatsby'
import axios from '../axios'
import { ENDPOINTS, ROUTES } from '../constants'
import Spinner from '../components/spinner'
import NotFound from './404'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import Card from '../components/trendingCard'
import ItemsPerPageFilter from '../components/ItemsPerPageFilter'
import { useAppState } from '../appState'
import { Link, navigate } from 'gatsby'
import MiddleSection from '../components/homeSections/homeMiddleSection'
import chirpyestProImg from '../assets/images/chirpyest-pro.jpg'

const PAGE_SIZE = 100

const ShopPage = ({ t }) => {
  const classes = styles()
  const [appState] = useAppState()

  const { search } = useLocation()
  const { page, size, sort, categoryName } = parse(search)

  const [pageNumber, setPageNumber] = useState(Number(page) || 1)
  const [pageSize, setPageSize] = useState(Number(size) || PAGE_SIZE)
  const [sorting, setSorting] = useState(sort || 'trending')
  const [selectedCategory, setSelectedCategory] = useState(
    categoryName || 'all'
  )
  const [productsCount, setProductsCount] = useState(null)
  const [products, setProducts] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [topProducts, setTopProducts] = useState([])
  const [error, setError] = useState('')

  const [isCopied, setIsCopied] = useState(false)
  const [randomPosition, setRandomPosition] = useState(null)

  // const [readMore, setReadMore] = useState(false)
  // const theme = useTheme()
  // const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  // const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  // const MAX_TEXT_LENGTH = isPhone ? 75 : isTablet ? 150 : 234 // to ensure that text lines match the design and show read more button

  const sortingOptions: any = {
    trending: {
      value: 'sort=trending',
      type: 'trending',
      label: t('memberHome.trending'),
    },
    highestToLowest: {
      value: 'sort=price&dir=desc',
      type: 'highestToLowest',
      label: t('shared.highestToLowest'),
    },
    lowestToHighest: {
      value: 'sort=price&dir=asc',
      type: 'lowestToHighest',
      label: t('shared.lowestToHighest'),
    },
  }
  const [categoryOptions, setCategoryOptions] = useState({})

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setProducts((items: any) => {
      const i = items.findIndex((e: any) => e.product_id === productId)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          shoppingBoardId,
        },
        ...items.slice(i + 1),
      ]
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.categories)
        let options: any = {
          all: { value: 'categoryName=all', type: 'all', label: 'all' },
        }
        for (const key in data) {
          options[key] = { value: 'categoryName=' + key, type: key, label: key }
        }
        setCategoryOptions(options)
        // console.log(options)
      } catch (err) {}
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const initPage = Number(page) || 1
        const initSort = sort || 'trending'
        const initSize = Number(size) || PAGE_SIZE

        setPageNumber(initPage)
        setSorting(initSort)
        setPageSize(initSize)
        setLoadingProducts(true)

        const trendingProducts: any[] = []
        const cutFrom = Math.max(
          (initPage - 1) * initSize -
            (trendingProducts.length > initSize ? 2 : 0),
          0
        )
        const cutTo = initPage * initSize
        const totalCut = Math.min(
          cutTo - cutFrom,
          Math.max(0, trendingProducts.length - cutFrom)
        )

        const reqSize = Math.max(
          (initPage === 1 ? initSize - 2 : initSize) - totalCut,
          0
        )
        const reqOffset =
          pageNumber === 1
            ? 0
            : Math.max(initSize * (initPage - 1) - 2 - topProducts.length, 0)

        const endpoint =
          `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}` +
          (selectedCategory === 'all'
            ? ''
            : '&categoryName=' + selectedCategory)
        const res = await axios.get(endpoint)

        setTopProducts(trendingProducts)
        setProducts([
          ...trendingProducts.slice(
            cutFrom,
            pageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
          ),
          ...res.data.data.results,
        ])

        let randomNumber = Math.floor(Math.random() * 15 + 8)
        const edgePositions = [11, 15, 19]
        edgePositions.includes(randomNumber) &&
          (randomNumber = randomNumber - 1)
        setRandomPosition(randomNumber)
        setProductsCount(res.data.data.total)
        setLoadingProducts(false)
      } catch (err) {
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [])

  const fetchNextPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)

      const cutFrom = Math.max(
        pageNumber * pageSize - (topProducts.length > pageSize ? 2 : 0),
        0
      )
      const cutTo = (pageNumber + 1) * pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const reqOffset = Math.max(
        pageSize * pageNumber - 2 - topProducts.length,
        0
      )
      const reqSize = Math.max(
        (pageSize === 1 ? pageSize - 2 : pageSize) - totalCut,
        0
      )
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}` +
        (selectedCategory === 'all' ? '' : '&categoryName=' + selectedCategory)
      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber +
          1}&size=${pageSize}&categoryName=${selectedCategory}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          pageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setPageNumber((p: number) => p + 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const fetchPreviousPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)

      const cutFrom = Math.max(
        (pageNumber - 2) * pageSize - (topProducts.length > pageSize ? 2 : 0),
        0
      )
      const cutTo = (pageNumber - 1) * pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const offset =
        pageNumber - 1 === 1
          ? 0
          : Math.max(pageSize * (pageNumber - 2) - 2 - topProducts.length, 0)
      const reqSize = Math.max(
        (pageNumber - 1 === 1 ? pageSize - 2 : pageSize) - totalCut,
        0
      )
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${offset}&${sortingOptions[sorting].value}` +
        (selectedCategory === 'all' ? '' : '&categoryName=' + selectedCategory)

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber -
          1}&size=${pageSize}&categoryName=${selectedCategory}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          pageNumber - 1 === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setPageNumber((p: number) => p - 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleCategoryChange = async ({ type, value }: any) => {
    try {
      setLoadingProducts(true)
      setSelectedCategory(type)
      const trendingProducts: any[] = []
      const cutFrom = 0
      const cutTo = pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, trendingProducts.length - cutFrom)
      )
      const reqSize = Math.max(pageSize - 2 - totalCut, 0)
      const reqOffset = 0
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}` +
        ('categoryName=all' === value ? '' : `&${value}`)
      const res = await axios.get(endpoint)

      setProducts([
        ...trendingProducts.slice(cutFrom, !reqSize ? cutTo - 2 : cutTo),
        ...res.data.data.results,
      ])
      setProductsCount(res.data.data.total)
      setPageNumber(1)
      setLoadingProducts(false)
      window.history.pushState(
        '',
        '',
        `?${sortingOptions[sorting].value}&page=1&size=${pageSize}&${value}`
      )
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleSorting = async ({ type, value }: any) => {
    try {
      setLoadingProducts(true)
      setSorting(type)
      const trendingProducts: any[] = []

      const cutFrom = 0
      const cutTo = pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, trendingProducts.length - cutFrom)
      )
      const reqSize = Math.max(pageSize - 2 - totalCut, 0)
      const reqOffset = 0
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${reqOffset}&${value}` +
        (selectedCategory === 'all' ? '' : '&categoryName=' + selectedCategory)
      const res = await axios.get(endpoint)

      setProducts([
        ...trendingProducts.slice(cutFrom, !reqSize ? cutTo - 2 : cutTo),
        ...res.data.data.results,
      ])
      setPageNumber(1)
      setLoadingProducts(false)
      window.history.pushState(
        '',
        '',
        `?sort=${type}&page=1&size=${pageSize}&categoryName=${selectedCategory}`
      )
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handlePageSizeChange = async (newSize: number) => {
    try {
      const totalSkippedItems = pageNumber * pageSize
      const newPageNumber = Math.ceil(totalSkippedItems / newSize)

      setLoadingProducts(true)
      setPageNumber(newPageNumber)
      setPageSize(newSize)

      const cutFrom = Math.max(
        (newPageNumber - 1) * newSize - (topProducts.length > newSize ? 2 : 0),
        0
      )
      const cutTo = newPageNumber * newSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const offset =
        pageNumber === 1
          ? 0
          : Math.max(newSize * (newPageNumber - 1) - 2 - topProducts.length, 0)
      const reqSize = Math.max(
        (newPageNumber === 1 ? newSize - 2 : newSize) - totalCut,
        0
      )
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${offset}&${sortingOptions[sorting].value}` +
        (selectedCategory === 'all' ? '' : '&categoryName=' + selectedCategory)
      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${newPageNumber}&size=${newSize}&categoryName=${selectedCategory}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          newPageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleCopyLink = () => {
    const joinLink = `${process.env.GATSBY_SITE_URL}/join/${appState.userProfile.userName}-${appState.userId}`
    navigator.clipboard.writeText(joinLink)
    setIsCopied(true)
  }

  const loadMoreClick = async () => {
    // console.log('loadMoreClick')
    try {
      // setLoadingProducts(true)
      const cutFrom = Math.max(
        pageNumber * pageSize - (topProducts.length > pageSize ? 2 : 0),
        0
      )
      const cutTo = (pageNumber + 1) * pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const reqOffset = Math.max(
        pageSize * pageNumber - 2 - topProducts.length,
        0
      )
      const reqSize = Math.max(
        (pageSize === 1 ? pageSize - 2 : pageSize) - totalCut,
        0
      )
      const endpoint =
        `${ENDPOINTS.allCategory}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}` +
        (selectedCategory === 'all' ? '' : '&categoryName=' + selectedCategory)
      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber +
          1}&size=${pageSize}&categoryName=${selectedCategory}`
      )

      const res = await axios.get(endpoint)

      setProducts([...products, ...res.data.data.results])
      // setProducts([
      //   ...topProducts.slice(
      //     cutFrom,
      //     pageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
      //   ),
      //   ...res.data.data.results,
      // ])
      setPageNumber((p: number) => p + 1)
      // setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  if (error) {
    return <NotFound error={error} />
  }

  return (
    <Layout>
      {/*Heading*/}
      <div className={classes.headingWrapper}>
        <div className={classes.headingContainer}>
          <div className={classes.headingTextContainer}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Typography color="textPrimary">shop</Typography>
            </Breadcrumbs>
            <div className={classes.headingLogoContainer}>
              <img
                className={classes.headingLogoImage}
                width={100}
                height={100}
                src={birdAvatar}
                alt="logo"
              />
              <div className={classes.headingLogoText}>
                <Typography
                  component="h1"
                  className={classes.categoryName}
                  style={{ marginBottom: 0 }}
                  variant="h1"
                >
                  {t('shared.shop')}
                </Typography>
                <Typography
                  component="p"
                  className={classes.categoryDescription}
                  variant="subtitle2"
                >
                  …till you drop
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.actionButtonsContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.filterButton}>
                <ProductsSort
                  label={t('shared.sortBy')}
                  onFilterChange={handleSorting}
                  selectedFilter={sortingOptions[sorting]}
                  options={Object.values(sortingOptions)}
                />
              </div>
              <div className={classes.filterButton2}>
                <ProductsSort
                  label={t('shared.category') + ':'}
                  onFilterChange={handleCategoryChange}
                  selectedFilter={categoryOptions[selectedCategory]}
                  options={Object.values(categoryOptions)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*products list*/}
      <div>
        <div className={classes.productsContainer}>
          {deviceType() !== 'mobile' && (
            <div className={classes.countingFilterContainer2}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item lg={3} md={4} sm={4} xs={6}>
                  <Typography
                    component="p"
                    className={classes.itemsCount}
                    variant="body1"
                  >
                    {productsCount}
                    {productsCount > 10000 ? '+' : ''} {t('shared.items')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={4}
                  xs={6}
                  style={{
                    backgroundColor: '#FAFBFD',
                    padding: 0,
                  }}
                >
                  <ItemsPerPageFilter
                    onFilterChange={handlePageSizeChange}
                    selectedFilter={pageSize}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          <Grid
            container
            alignItems="stretch"
            spacing={2}
            style={{ minHeight: '5vh' }}
          >
            {loadingProducts ? (
              <Spinner />
            ) : (
              products.map((product: any, index: any) => {
                if (index === randomPosition && pageNumber === 1) {
                  return (
                    <>
                      <Grid item lg={6} md={8} sm={8} xs={12}>
                        <div className={classes.joinCard}>
                          <Typography variant="h4" className={classes.cardText}>
                            share chirpyest with friends and earn $
                          </Typography>
                          <Grid container justifyContent="center">
                            {!appState.userId ? (
                              <Link
                                className={classes.joinButton}
                                target="_blank"
                                to={ROUTES.join}
                              >
                                <Typography variant="button">
                                  Join us
                                </Typography>
                              </Link>
                            ) : (
                              <div
                                className={classes.joinButton}
                                onClick={handleCopyLink}
                              >
                                <Typography variant="button">
                                  {isCopied ? 'copied' : 'copy join link'}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={6}
                        key={product.product_id}
                      >
                        <Card
                          updateProducts={updateProducts}
                          // onRemove={removeProduct}
                          userId={appState.userId}
                          productInfo={product}
                          shareable
                          isPriceBottomOnMobile
                        />
                      </Grid>
                    </>
                  )
                }
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={4}
                    xs={6}
                    key={product.product_id}
                  >
                    <Card
                      updateProducts={updateProducts}
                      // onRemove={removeProduct}
                      userId={appState.userId}
                      productInfo={product}
                      shareable
                      isPriceBottomOnMobile
                    />
                  </Grid>
                )
              })
            )}
          </Grid>
          {deviceType() === 'desktop' ? (
            <div className={classes.paginationContainer}>
              <CHRArrowButton
                isLeftArrow
                label={t('shared.previous')}
                onClick={fetchPreviousPage}
                disabled={pageNumber === 1 || !productsCount}
              />
              <Typography variant="subtitle2" component="p">
                {pageNumber}/{Math.ceil(productsCount / pageSize) || 1}
              </Typography>
              <CHRArrowButton
                label={t('shared.next')}
                onClick={fetchNextPage}
                disabled={
                  pageNumber === Math.ceil(productsCount / pageSize) ||
                  !productsCount
                }
              />
            </div>
          ) : (
            <div
              className={classes.joinButton}
              onClick={loadMoreClick}
              style={{ margin: 'auto', marginTop: 30 }}
            >
              <Typography variant="button">load more</Typography>
            </div>
          )}
        </div>
      </div>
      {/* Pro section */}
      <CHRSectionContainer>
        <section>
          <MiddleSection
            handleArrowClick={() => navigate(ROUTES.chirpyestPro)}
            image={chirpyestProImg}
            buttonLabel={t('memberHome.learnMore')}
            rightChildren={
              <div>
                <Typography variant="h3">{t('memberHome.proTitle')}</Typography>
                <Typography variant="h2">
                  {t('memberHome.proSubtitle')}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {t('memberHome.proText')}
                </Typography>
              </div>
            }
          />
        </section>
      </CHRSectionContainer>
    </Layout>
  )
}

export default withTranslation()(ShopPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: { fluid: { originalName: { in: ["getting-hang-of-it.png"] } } }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
